import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import LContainer from '@/layout/LContainer.vue'
import { check_login } from "@/assets/js/api/userServices";
import  store  from "../store/index";

const routes: Array<RouteRecordRaw> = [{
    path: "/",
    redirect: "/index",
    name: "index",
    component: LContainer,
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import('@/views/L/LIndex.vue'),
        meta: {
          title: "index",
        },
      },
      {
        path: "/branding",
        name: "branding",
        component: () => import('@/views/L/LBranding.vue'),
        meta: {
          title: "branding",
        },
      },
      {
        path: "/graphic",
        name: "graphic",
        component: () => import('@/views/L/LGraphic.vue'),
        meta: {
          title: "graphic",
        },
      },
      {
        path: "/typesetting",
        name: "typesetting",
        component: () => import('@/views/L/LTypesetting.vue'),
        meta: {
          title: "typesetting",
        },
      },
      {
        path: "/project",
        name: "project",
        component: () => import('@/views/L/LProject.vue'),
        meta: {
          title: "新闻",
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import('@/views/L/LContact.vue'),
        meta: {
          title: "contact",
        },
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(function (to, from, next) {
  check_login().then(res=>{
    store.state.user_info = res;
  })
  window.scrollTo(0, 0)
  next()
})
export default router;
