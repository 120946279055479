import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  Canceler,
  AxiosResponse
} from "axios";
import QS from "qs";
import { ElMessage, MessageParams } from "element-plus";
import router from "@/router";


// 服务器域名
export const SERVER_HOST = process.env.BASE_URL;
// 上传文件
export const UPLOAD_PATH = SERVER_HOST + "/file/upload";
// 下载文件
export const DOWNLOAD_PATH = SERVER_HOST + "/file/download";
// 多文件上传
export const MULTIPLE_UPLOAD_PATH = SERVER_HOST + "/file/uploadFiles";
// 文件预览
export const PREVIEW_FILE = SERVER_HOST + "/file/preview?filePath=";
// 初始化Axios
export const httpService: AxiosInstance = axios.create({
  baseURL: SERVER_HOST,
  // origin
  timeout: 300000,
  paramsSerializer: function (params: any) {
    return QS.stringify(params, { arrayFormat: "repeat" });
  },
});
// 拦截器
httpService.interceptors.request.use(requestInterceptor, (error: any) => {
  return Promise.reject(error);
});
httpService.interceptors.response.use(
  responseInterceptor,
  (error: { message: MessageParams | undefined }) => {
    if (error.message) {
      ElMessage.error(error.message);
    }
  }
);
/**
 * @Author Lin
 * Axios拦截器封装
 * */
// 允许重复请求的接口
const ALLOW_REPEAT: string[] = [
  '/v1/artwork/list',
  '/v1/contact/contact_add'
];
// 阻止重复请求
const requestPending = new Map<string, Canceler>();
const generateKey = (config: AxiosRequestConfig) => {
  let res = "key";
  if (config.url) {
    res += config.url;
  }
  if (config.method) {
    res += config.method.toLowerCase();
  }
  return res;
};
const cancelRequest = (config: AxiosRequestConfig) => {
  const pending: string = generateKey(config);
  const cancel: Canceler = requestPending.get(pending) as Canceler;
  if (cancel) {
    cancel();
    requestPending.delete(pending);
  }
};
function requestInterceptor(config: AxiosRequestConfig) {
  if (!(config.url && ALLOW_REPEAT.includes(config.url))) {
    cancelRequest(config);
    config.cancelToken = new axios.CancelToken((cancel: any) => {
      requestPending.set(generateKey(config), cancel);
    });
  }
  return config;
}
// 响应拦截
export function responseInterceptor(response: AxiosResponse) {
  if (response.status && response.status === 200) {
    return response.data.data !== null ? response.data.data : true;
  } else {
    ElMessage.error(response.data.message || "Error");
  }
}
