import { httpService } from "@/assets/js/httpServices";
import { LoginVM, NewsListVM } from "@/assets/js/api/userServices.d";

export function login(data: LoginVM) {
  return httpService.post("/v1/home/login", data);
}
export function check_login() {
  return httpService.post("/v1/home/check_login");
}
export function contact_info() {
  return httpService.post("/v1/contact/info");
}
export function about_info() {
  return httpService.post("/v1/about/info");
}
export function about_add(data:any) {
  return httpService.post("/v1/about/about_add",data);
}

export function branding_list(page = 0, limit = 20) {
  return httpService.post("/v1/branding/list", {
    page: page,
    limit: limit,
  });
}

export function project_list(page = 0, limit = 20) {
  return httpService.post("/v1/project/list", {
    page: page,
    limit: limit,
  });
}

export function typesetting_list(page = 0, limit = 20) {
  return httpService.post("/v1/typesetting/list", {
    page: page,
    limit: limit,
  });
}

export function graphic_list(page = 0, limit = 20) {
  return httpService.post("/v1/graphic/list", {
    page: page,
    limit: limit,
  });
}

export function branding_del(id: number) {
  return httpService.post("/v1/branding/branding_del", {
    branding_id: id,
  });
}
export function typesetting_del(id: number) {
  return httpService.post("/v1/typesetting/typesetting_del", {
    typesetting_id: id,
  });
}
export function project_del(id: number) {
  return httpService.post("/v1/project/project_del", {
    project_id: id,
  });
}
export function graphic_del(id: number) {
  return httpService.post("/v1/graphic/graphic_del", {
    graphic_id: id,
  });
}
export function contact_del(id: number) {
  return httpService.post("/v1/contact/contact_del", {
    contact_id: id,
  });
}

export function branding_add(data: any) {
  return httpService.post("/v1/branding/branding_add", data);
}
export function typesetting_add(data: any) {
  return httpService.post("/v1/typesetting/typesetting_add", data);
}
export function project_add(data: any) {
  return httpService.post("/v1/project/project_add", data);
}
export function graphic_add(data: any) {
  return httpService.post("/v1/graphic/graphic_add", data);
}
export function contact_add(data: any) {
  return httpService.post("/v1/contact/contact_add", data);
}

export function branding_sort(list: any) {
  return httpService.post("/v1/branding/edit_sort", {
    list: list,
  });
}
