export default {
  zh: {
    home: '首页',
    news: '新闻',
    artworks: '作品',
    exhibitions: '展览',
    contact: '关于',
    follow: '关注我',
  },
  tc: {
    home: '首頁',
    news: '新聞',
    artworks: '作品',
    exhibitions: '展覽',
    contact: '關於',
    follow: '關注我',
  },
  en: {
    home: 'HOME',
    news: 'NEWS',
    artworks: 'ARTWORKS',
    exhibitions: 'EXHIBITIONS',
    contact: 'CONTACT',
    follow: 'FOLLOW US',
  },
  fr: {
    home: 'HOME',
    news: 'NEWS',
    artworks: 'ARTWORKS',
    exhibitions: 'EXHIBITIONS',
    contact: 'CONTACT',
    follow: 'FOLLOW US',
  }
}
