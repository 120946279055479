export default {
  zh: {
    about: '关于YUGINI',
    mail: `yupei21jade{'@'}gmail.com`,
    paragraph1: 'YUGINI est une artiste et designer qui asuivi un cursus de Master d\'Art à l\'université Paris 8. Son travail est lié àla régénération des espaces naturels et à l\'écologie ainsi qu\'à la sciencespirituelle du cycle de vie.',
    paragraph2: 'Elle éveille ainsi le public à la conscience de l\'environnement, ainsiqu\'à l\'importance de l\'esprit dans les processus de perception ou de création.Ses projets pluridisciplinaires associent la résonance visuelle et sonore, lapeinture et la chorégraphie, les performances, installations, vidéo, poésie,design, méditation, acupuncture…YUGINI met en lumière les expériences liées auxénergies.',
    biography: '自传',
    articles: '作品',
    news: '新闻',
    collection: '作品集',
    edu:'教育背景',
    work:'工作',
    exp:'经历',
    skill:'技能&',
    tool:'工具',
    add:"添加一条",
    del:'删除'

  },
  en: {
    about: 'ABOUT YUGINI',
    mail: `yupei21jade{'@'}gmail.com`,
    paragraph1: 'YUGINI est une artiste et designer qui asuivi un cursus de Master d\'Art à l\'université Paris 8. Son travail est lié àla régénération des espaces naturels et à l\'écologie ainsi qu\'à la sciencespirituelle du cycle de vie.',
    paragraph2: 'Elle éveille ainsi le public à la conscience de l\'environnement, ainsiqu\'à l\'importance de l\'esprit dans les processus de perception ou de création.Ses projets pluridisciplinaires associent la résonance visuelle et sonore, lapeinture et la chorégraphie, les performances, installations, vidéo, poésie,design, méditation, acupuncture…YUGINI met en lumière les expériences liées auxénergies.',
    biography: 'BIOGRAPHY',
    articles: 'ARTICLES',
    news: 'NEWS',
    collection: 'COLLECTIONS',
    edu:'EDUCATION',
    work:'WORK',
    exp:'EXPERIENCE',
    skill:'SKILLS&',
    tool:'TOOLS',
    add:"Add item",
    del:'delete'
  },
  tc: {
    about: '關於YUGINI',
    mail: `yupei21jade{'@'}gmail.com`,
    paragraph1: 'YUGINI est une artiste et designer qui asuivi un cursus de Master d\'Art à l\'université Paris 8. Son travail est lié àla régénération des espaces naturels et à l\'écologie ainsi qu\'à la sciencespirituelle du cycle de vie.',
    paragraph2: 'Elle éveille ainsi le public à la conscience de l\'environnement, ainsiqu\'à l\'importance de l\'esprit dans les processus de perception ou de création.Ses projets pluridisciplinaires associent la résonance visuelle et sonore, lapeinture et la chorégraphie, les performances, installations, vidéo, poésie,design, méditation, acupuncture…YUGINI met en lumière les expériences liées auxénergies.',
    biography: '自傳',
    articles: '作品',
    news: '新聞',
    collection: '作品集',
    edu:'教育背景',
    work:'工作',
    exp:'經歷',
    skill:'技能&',
    tool:'工具',
    add:"添加一條",
    del:'刪除'
  },
  fr: {
    about: 'ABOUT YUGINI',
    mail: `yupei21jade{'@'}gmail.com`,
    paragraph1: 'YUGINI est une artiste et designer qui asuivi un cursus de Master d\'Art à l\'université Paris 8. Son travail est lié àla régénération des espaces naturels et à l\'écologie ainsi qu\'à la sciencespirituelle du cycle de vie.',
    paragraph2: 'Elle éveille ainsi le public à la conscience de l\'environnement, ainsiqu\'à l\'importance de l\'esprit dans les processus de perception ou de création.Ses projets pluridisciplinaires associent la résonance visuelle et sonore, lapeinture et la chorégraphie, les performances, installations, vidéo, poésie,design, méditation, acupuncture…YUGINI met en lumière les expériences liées auxénergies.',
    biography: 'BIOGRAPHY',
    articles: 'ARTICLES',
    news: 'NEWS',
    collection: 'COLLECTIONS',

  },
}
