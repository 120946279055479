import header from '../langEnum/header'
import index from '../langEnum/index'
import artworks from '../langEnum/artworks'
import contact from '../langEnum/contact'
import side from "@/assets/js/i18n/langEnum/side";

const lang = 'tc'
export default {
  header: header[lang],
  index: index[lang],
  artworks: artworks[lang],
  contact: contact[lang],
  side: side[lang]
}
