export default {
  zh: {
    home: '首页',
    branding: '品牌设计',
    graphic: '平面设计',
    typesetting: '排版设计',
    project: '主导项目',
    contact: '关于',
    Editor:'启用编辑',
    closeEditor:'关闭编辑',
  },
  tc: {
    home: '首頁',
    branding: '品牌設計',
    graphic: '平面設計',
    typesetting: '擺版設計',
    project: '主導項目',
    contact: '關於',
    Editor:'啓用編輯',
    closeEditor:'關閉編輯',
  },
  en: {
    home: 'HOME',
    branding: 'BRANDING',
    graphic: 'GRAPHIC DESIGN',
    typesetting: 'TYPESETTING',
    project: 'LEADING PROJECT',
    contact: 'CONTACT',
    Editor:'ENABLE EDITING',
    closeEditor:'Close Editing',
  }
}
